document.addEventListener("DOMContentLoaded", function() {

    const menuForm = document.getElementById('menuForm');
    const preloader = document.getElementById('preloader');
    const rangeElements = document.querySelectorAll('.form-range');

    //init range inputs
    const inputEvent = new Event('input');
    rangeElements.forEach(function(element) {
        element.dispatchEvent(inputEvent);
    });

    document.getElementById('menuForm').addEventListener('submit', function(e) {
        e.preventDefault();
        showPreloader();

        let form = event.target;
        let formData = new FormData(form);

        fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest' // Tell Symfony this is an AJAX request
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    document.getElementById('menu-result').innerHTML = data.menu;
                }
                hidePreloader();
            })
            .catch(error => {
                console.error('Error:', error);
                hidePreloader();
            });
    });

    function showPreloader() {
        preloader.classList.remove('d-none')
        menuForm.classList.add('d-none');
    }

    function hidePreloader() {
        preloader.classList.add('d-none');
    }
});


function updateRangeValue(input) {
    const target = input.getAttribute('data-target');
    document.getElementById(target).innerText = input.value;
}

window.updateRangeValue = updateRangeValue;
