import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
document.addEventListener("DOMContentLoaded", function() {

    CookieConsent.run({

        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
            },
            analytics: {
                autoClear: {
                    cookies: [
                        {
                            name: /^_ga/,   // regex: match all cookies starting with '_ga'
                        },
                        {
                            name: '_gid',   // string: exact cookie name
                        }
                    ]
                },

                // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
                services: {
                    ga: {
                        label: 'Google Analytics',
                        onAccept: () => {},
                        onReject: () => {}
                    },
                    youtube: {
                        label: 'Youtube Embed',
                        onAccept: () => {},
                        onReject: () => {}
                    },
                }
            },
            ads: {}
        },

        language: {
            default: 'en',
            translations: {
                en: {
                    consentModal: {
                        title: 'Cookies',
                        description: 'Používáme cookies. Můžete je vypnout v <button type="button" class="cc-setting-link" data-cc="show-preferencesModal" aria-haspopup="dialog">nastavení</button>.',
                        acceptAllBtn: 'OK',
                        acceptNecessaryBtn: '',
                        showPreferencesBtn: ''
                    },
                    preferencesModal: {
                        title: 'Nastavení cookies',
                        acceptAllBtn: 'Přijmout vše',
                        acceptNecessaryBtn: 'Odmítnout vše',
                        savePreferencesBtn: 'Přijmout aktuální výběr',
                        closeIconLabel: 'Zavřít okno',
                        sections: [
                            {
                                title: ' ',
                                description: 'Pro fungování webových stránek Jidelnicek.onlysport.cz používáme cookies a podobné technologie, které ukládají naše stránky do vašeho zařízení prostřednictvím prohlížeče nebo z něj čtou. Cookies mohou zaznamenávat určité konkrétní informace z vašich návštěv webových stránek. Na základě jejich účelu můžeme cookies rozdělit na funkční (nezbytné), analytické a marketingové cookies. Respektujeme vaše právo na soukromí, proto se můžete rozhodnout určité cookies nepřijmout. Kliknutím na <strong>"Přijmout vybrané"</strong> bez vybraných preferencí budou všechny volitelné cookies zablokovány. <br/><br/>Více informací naleznete na stránce <a href="/ochrana-osobnich-udaju/">Ochrana osobních údajů</a>.'
                            },
                            {
                                title: 'Funkční cookies',
                                description: 'Nezbytné cookies jsou takové, které jsou nezbytné pro správné fungování webových stránek, jejich zabezpečení nebo poskytování jejich základních funkcí a služeb. Proto tyto cookies nelze odmítnout a nepotřebujeme váš souhlas k jejich zpracování.',

                                //this field will generate a toggle linked to the 'necessary' category
                                linkedCategory: 'necessary'
                            },
                            {
                                title: 'Analytické cookies',
                                description: 'Tyto cookies shromažďují informace o tom, jak používáte naše webové stránky. Všechna data jsou anonymizována a nelze je použít k vaší identifikaci.',
                                linkedCategory: 'analytics',
                                cookieTable: {
                                    caption: 'Cookie table',
                                    headers: {
                                        name: 'Cookie',
                                        domain: 'Doména',
                                        desc: 'Popis'
                                    },
                                    body: [
                                        {
                                            name: '_ga',
                                            domain: location.hostname,
                                            desc: '-'
                                        },
                                        {
                                            name: '_gid',
                                            domain: location.hostname,
                                            desc: '-'
                                        }
                                    ]
                                }
                            },

                        ]
                    }
                }
            }
        }
    });



});